import React from 'react'

const PaymentPane = ({ show, showFees, totalWithFeesFormatted, feeAmountFormatted, feeDescription, children }) => {
  const processingFee = feeDescription ? `Includes ${feeAmountFormatted} processing fee` : "No processing fee"

  return (
    <div className={`panel panel-default spacer-bottom ${show ? "" : "d-none"}`}>
      <div className="panel-body">
        <>
          <div className="fs-3 payment-amount">Pay: { totalWithFeesFormatted }</div>
          { showFees && <p className="processing-fee">{ processingFee }</p> }
        </>
        { children }
      </div>
    </div>
  )
}

export default PaymentPane
