import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Form, Button, Modal, Row, Col, FormGroup, FormControl } from 'react-bootstrap'
import ColorPicker from '../ColorPicker'
import EventTypeSelector from '../EventTypeSelector'
import CopyableTextBlock from '../CopyableTextBlock'
import stringifyProps from './stringifyProps'

export default class AddOnWidgetButton extends Component {
  state = {
    showModal: false,
    style: "embed",
    showBookNowButton: true,
    bookNowButtonText: "Book Now",
    bookNowBehavior: "default",
    showPrice: true,
    showAllCategories: true,
    selectedCategoryIds: [],
    selectedEventTypeId: this.props.eventCategories[0].packageGroups[0].eventTypes[0].id,
    showAdvancedStylingOptions: false,
    bookingEventTypeId: null,
    buttonBackgroundColor: "",
    buttonForegroundColor: "",
    textColor: "",
    iframe: false,
    maxWidth: "",
  }

  static propTypes = {
    type: PropTypes.string.isRequired,
    host: PropTypes.string.isRequired,
    apiKey: PropTypes.string.isRequired,
    addOnCategoryIds: PropTypes.array,
    eventCategories: PropTypes.array,
  }

  showModal = () => {
    this.setState({showModal: true})
  }

  hideModal = () => {
    this.setState({showModal: false})
  }

  showAdvancedStylingOptions = (e) => {
    e.preventDefault()
    this.setState({ showAdvancedStylingOptions: true })
  }

  hideAdvancedStylingOptions = (e) => {
    e.preventDefault()
    this.setState({ showAdvancedStylingOptions: false })
  }

  get html() {
    const { style, showBookNowButton, bookNowButtonText, showPrice,
      showAdvancedStylingOptions, buttonBackgroundColor, buttonForegroundColor,
      textColor, showAllCategories, selectedCategoryIds, bookNowBehavior, selectedEventTypeId, iframe, maxWidth } = this.state
    const { host, apiKey } = this.props

    const baseProps = { apiKey, iframe, host: `https://${host}` }
    let dataProps = { showBookNowButton, bookNowButtonText, showPrice }
    if(maxWidth) {
      dataProps.maxWidth = maxWidth
    }
    if(showAdvancedStylingOptions) {
      if(buttonBackgroundColor) {
        dataProps.buttonBackgroundColor = buttonBackgroundColor
      }
      if(buttonForegroundColor) {
        dataProps.buttonForegroundColor = buttonForegroundColor
      }
      if(textColor) {
        dataProps.textColor = textColor
      }
    }

    if(!showAllCategories) {
      dataProps.addOnCategoryIds = selectedCategoryIds
    }

    if(bookNowBehavior == "package") {
      dataProps.bookNowPackageId = selectedEventTypeId
    }

    const combinedProps = {...baseProps, ...dataProps}

    if(style === "basicLink") {
      let url = `https://${host}/add_ons`
      if(Object.keys(dataProps).length > 0) {
        url = `${url}?props=${encodeURIComponent(stringifyProps(dataProps))}`
      }
      return url
    }
    else if(style === "embed" && !iframe) {
      return `<script src="https://${host}/api/checkcherry_widgets" type="text/javascript" charset="utf-8"></script><div class="checkcherry__widget__add-on-gallery" data-props='${stringifyProps(combinedProps)}'></div>`
    }
    else if(style === "embed" && iframe) {
      return `<iframe class="checkcherry-autoresize-frame" src="https://${host}/add_ons/?iframe=true&props=${encodeURIComponent(stringifyProps(dataProps))}" style="margin: 0; padding: 0; border: none; width: ${maxWidth || "100%"}; height: 400px" scrolling="auto" allowtransparency="true"></iframe><script src="https://${host}/api/checkcherry_widgets/iframe" type="text/javascript" charset="utf-8"></script>`
    }
    else {
      return ""
    }
  }

  toggleCategory = (id) => {
    const { selectedCategoryIds } = this.state
    if(selectedCategoryIds.includes(id)) {
      this.setState({ selectedCategoryIds: selectedCategoryIds.filter(i => i !== id) })
    }
    else {
      this.setState({ selectedCategoryIds: [...selectedCategoryIds, id] })
    }
  }

  render() {
    const { type, addOnCategories, eventCategories } = this.props
    const { showModal, style, showBookNowButton, bookNowButtonText, showPrice,
      showAdvancedStylingOptions, textColor, buttonBackgroundColor,
      buttonForegroundColor, showAllCategories, bookNowBehavior, selectedEventTypeId,
      iframe, maxWidth } = this.state

    return (
      <div>
        <div className="btn-group">
          <Button onClick={this.showModal} variant="primary">
            <i className="fa fa-code" />
            { " " }
            Get Embed Code
          </Button>
        </div>
        <Modal show={showModal} onHide={this.hideModal} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Add On Gallery Widget</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col sm={5}>
                <h3>Customize</h3>
                <FormGroup className="mb-2">
                  <Form.Label>Choose Style</Form.Label>
                  <Form.Check
                    type="radio"
                    name="style"
                    checked={style === "basicLink"}
                    onChange={() => this.setState({style: "basicLink"})}
                    label="Link Only"
                    id="add-on-widget-link-only"
                  />
                  <Form.Check
                    type="radio"
                    name="style"
                    checked={style === "embed"}
                    onChange={() => this.setState({style: "embed"})}
                    label="Embed on my site"
                    id="add-on-widget-embed"
                  />
                </FormGroup>
                <FormGroup className="mb-2">
                  <Form.Label>Show Book Now Button?</Form.Label>
                  <Form.Check
                    type="radio"
                    name="showBookNowButton"
                    checked={showBookNowButton === true}
                    onChange={() => this.setState({showBookNowButton: true})}
                    label="Yes"
                    id="add-on-widget-show-book-now-button-yes"
                  />
                  <Form.Check
                    type="radio"
                    name="showBookNowButton"
                    checked={showBookNowButton === false}
                    onChange={() => this.setState({showBookNowButton: false})}
                    label="No"
                    id="add-on-widget-show-book-now-button-no"
                  />
                </FormGroup>
                { showBookNowButton &&
                  <FormGroup className="mb-2">
                    <Form.Label>Button Text</Form.Label>
                    <FormControl
                      type="bookNowButtonText"
                      value={bookNowButtonText}
                      onChange={(e) => this.setState({bookNowButtonText: e.target.value})}
                    />
                  </FormGroup>
                }
                <FormGroup className="mb-2">
                  <Form.Label>Show Pricing?</Form.Label>
                  <Form.Check
                    type="radio"
                    name="showPrice"
                    checked={showPrice === true}
                    onChange={() => this.setState({showPrice: true})}
                    label="Yes"
                    id="add-on-widget-show-price-yes"
                  />
                  <Form.Check
                    type="radio"
                    name="showPrice"
                    checked={showPrice === false}
                    onChange={() => this.setState({showPrice: false})}
                    label="No"
                    id="add-on-widget-show-price-no"
                  />
                </FormGroup>
                <FormGroup className="mb-2">
                  <Form.Label>Show All Categories?</Form.Label>
                  <Form.Check
                    type="radio"
                    name="showAllCategories"
                    checked={showAllCategories === true}
                    onChange={() => this.setState({showAllCategories: true})}
                    label="Yes"
                    id="add-on-widget-show-all-categories-yes"
                  />
                  <Form.Check
                    type="radio"
                    name="showAllCategories"
                    checked={showAllCategories === false}
                    onChange={() => this.setState({showAllCategories: false})}
                    label="No"
                    id="add-on-widget-show-all-categories-no"
                  />
                </FormGroup>
                { !showAllCategories &&
                  <FormGroup className="mb-2">
                    <Form.Label>Categories</Form.Label>
                    { addOnCategories.map(addOnCategory =>
                      <Form.Check
                        type="checkbox"
                        key={addOnCategory.id}
                        label={addOnCategory.name}
                        value={addOnCategory.id}
                        checked={this.state.selectedCategoryIds.includes(addOnCategory.id)}
                        onChange={() => this.toggleCategory(addOnCategory.id)}
                        id={`add-on-widget-category-${addOnCategory.id}`}
                      />
                    )}
                  </FormGroup>
                }
                { style === "embed" &&
                  <FormGroup className="mb-2">
                    <Form.Label>Enable Compatibility Mode?</Form.Label>
                    <Form.Check
                      type="radio"
                      name="iframe"
                      checked={iframe === true}
                      onChange={() => this.setState({iframe: true})}
                      label="Yes"
                      id="add-on-widget-iframe-yes"
                    />
                    <Form.Check
                      type="radio"
                      name="iframe"
                      checked={iframe === false}
                      onChange={() => this.setState({iframe: false})}
                      label="No"
                      id="add-on-widget-iframe-no"
                    />
                    <Form.Text muted>Compatibility mode embeds widget within an iFrame for greater compatibility with some website builders</Form.Text>
                  </FormGroup>
                }
                { (style === "embed" && iframe) &&
                  <FormGroup className="mb-2">
                    <Form.Label>Maximum Widget Width</Form.Label>
                    <FormControl
                      type="text"
                      placeholder="Width in pixels or percentage"
                      value={maxWidth}
                      onChange={e => this.setState({ maxWidth: e.target.value })}
                    />
                  </FormGroup>
                }
                { !showAdvancedStylingOptions &&
                  <div>
                    <a href="#" onClick={this.showAdvancedStylingOptions}>
                      Show Advanced Options
                    </a>
                  </div>
                }
                { showAdvancedStylingOptions &&
                  <div>
                    <h3>Advanced</h3>
                    <FormGroup className="mb-2">
                      <Form.Label>When Booking:</Form.Label>
                      <Form.Check
                        type="radio"
                        name="bookNowBehavior"
                        checked={bookNowBehavior === "default"}
                        onChange={() => this.setState({bookNowBehavior: "default"})}
                        label="Take customer to package selection page"
                        id="book-now-behavior-default"
                      />
                      <Form.Check
                        type="radio"
                        name="bookNowBehavior"
                        checked={bookNowBehavior === "package"}
                        onChange={() => this.setState({bookNowBehavior: "package"})}
                        label="Pre-select a specific package"
                        id="book-now-behavior-package"
                      />
                    </FormGroup>
                    { bookNowBehavior === "package" &&
                    <FormGroup className="mb-2">
                      <Form.Label>Choose Package</Form.Label>
                      <Form.Control
                        as="select"
                        value={selectedEventTypeId}
                        onChange={(e) => this.setState({selectedEventTypeId: e.target.value})}
                      >
                        { eventCategories.map(eventCategory => {
                          return eventCategory.packageGroups.map(packageGroup => {
                            return (
                              <optgroup 
                                key={packageGroup.id}
                                label={`${eventCategory.name} - ${packageGroup.name}`}
                              >
                                { packageGroup.eventTypes.map(eventType => (
                                  <option key={eventType.id} value={eventType.id}>
                                    {eventType.name}
                                  </option>
                                )) }
                              </optgroup>
                            )
                          })
                        })}
                      </Form.Control>
                    </FormGroup>
                    }

                    <Row>
                      <Col sm={12}>
                        <Form.Label>Override Text Color</Form.Label>
                        <ColorPicker
                          value={textColor}
                          onChange={value => this.setState({ textColor: value })}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={12}>
                        <Form.Label>Override Button Background Color</Form.Label>
                        <ColorPicker
                          value={buttonBackgroundColor}
                          onChange={value => this.setState({ buttonBackgroundColor: value })}
                        />
                      </Col>
                      <Col sm={12}>
                        <label>Override Button Text Color</label>
                        <ColorPicker
                          value={buttonForegroundColor}
                          onChange={value => this.setState({ buttonForegroundColor: value })}
                        />
                      </Col>
                    </Row>
                  </div>
                }
              </Col>
              <Col sm={{ span: 5, offset: 2 }}>
                <h3>HTML</h3>

                <p>Copy and paste the HTML below into your website to add your contact form</p>

                <FormGroup>
                  <CopyableTextBlock value={this.html} />
                </FormGroup>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.hideModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    )
  }
}

const styles = {
  previewStyle: {
    wordWrap: 'break-word'
  },
  embedStyle: {
    height: 300
  }
}
