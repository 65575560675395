import React  from 'react'
import { loadStripe } from '@stripe/stripe-js';
import StripeExpressElementsForm from './StripeExpressElementsForm'
import { Elements } from '@stripe/react-stripe-js';

class StripeExpressManager extends React.Component {
  stripePromise = loadStripe(this.props.paymentMethod.stripePublishableKey, { stripeAccount: this.props.paymentMethod.stripeAccountId });

  render() {
    const { activePaymentMethodOption, stripeClientSecret, paymentMethod, paymentMethodOption } = this.props
    const { paymentMethodTypes } = paymentMethodOption

    const options = {
      // clientSecret: stripeClientSecret,
      paymentMethodCreation: 'manual',
      captureMethod: 'automatic',
      mode: 'payment',
      amount: this.props.amountCents,
      currency: 'usd',
      payment_method_types: paymentMethodTypes,
    }


    // Hide if another payment method option is selected
    if(activePaymentMethodOption.id && activePaymentMethodOption.id !== paymentMethodOption.id) {
      return null
    }

  // Hide if the current payment method option is selected but the "saved-card" special is set
  if(activePaymentMethodOption.id === paymentMethodOption.id && activePaymentMethodOption.special === "saved-cards") {
    return null
  }

    return (
      <Elements stripe={this.stripePromise} options={options}>
        <StripeExpressElementsForm {...this.props} />
      </Elements>
    );
  }
}

export default StripeExpressManager
